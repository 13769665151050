import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import RenderAsset from '@shared/ui/display/table-renderers/RenderAsset';
import RenderEntryPrice from '@shared/ui/display/table-renderers/RenderEntryPrice';
import RenderPnl from '@shared/ui/display/table-renderers/RenderPnl';
import RenderAssetDuration from '@shared/ui/display/table-renderers/RenderAssetDuration';
import RenderOrderSize from '@shared/ui/display/table-renderers/RenderOrderSize';
import { useGetPortfolioPositionsHistoryQuery } from '@shared/api/portfolio';
import tableRendererWrapper from '@shared/ui/display/Table/tableRendererWrapper';
import Table from '@shared/ui/display/Table';
import { ContainerColumn, SurfaceColumn } from '@components/styled';
import TableBodyRow from '@shared/ui/display/Table/components/TableBodyRow';
import { BodySmall } from '@components/styled/Typography';
import { formatDate } from '@utils/date-time';
import TableBodyCell from '@shared/ui/display/Table/components/TableBodyCell';
import { IsDefined } from '@utils/js-ts';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import PnlHeaderCell from '../PnlHeaderCell';
const MinHeight = 684;
const Container = styled(SurfaceColumn)(props => ({
    boxSizing: 'border-box',
    height: 'auto',
    padding: props.theme.spacing_sizes.l,
    // TODO: move shadow to theme
    boxShadow: '0px 1px 3px 0px #E6E8EB',
    alignItems: 'center',
    justifyContent: 'center',
}));
const LoaderContainer = styled(ContainerColumn)(() => ({
    flex: 1,
    minHeight: MinHeight,
    alignItems: 'center',
    justifyContent: 'center',
}));
const isPositionHistoryRow = (row) => row.pnl_percent !== undefined;
const positionsHistoryRenderer = (renderer) => (params) => {
    if (isPositionHistoryRow(params.row.original)) {
        return renderer(params.cell.getContext());
    }
    return null;
};
const getRowId = (row, index) => {
    if (isPositionHistoryRow(row)) {
        return row.id;
    }
    return `${row.month || `row-${index}`}-${row.count || 0}`;
};
const getColumnsDefinition = (t) => ([
    {
        header: t('table.current_positions.asset'),
        accessorKey: 'asset',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: positionsHistoryRenderer(tableRendererWrapper(RenderAsset)),
    },
    {
        header: t('table.current_positions.order_size'),
        accessorKey: 'order_size',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: positionsHistoryRenderer(tableRendererWrapper(RenderOrderSize)),
    },
    {
        header: t('table.current_positions.entry_price'),
        accessorKey: 'entry_price',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: positionsHistoryRenderer(tableRendererWrapper(RenderEntryPrice)),
    },
    {
        header: PnlHeaderCell,
        accessorKey: 'pnl',
        enableSorting: false,
        meta: {
            flex: 1,
            align: 'right',
            headerAlign: 'right',
        },
        cell: positionsHistoryRenderer(tableRendererWrapper(RenderPnl)),
    },
    {
        header: t('table.current_positions.duration'),
        accessorKey: 'opened',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: positionsHistoryRenderer(tableRendererWrapper(RenderAssetDuration)),
    },
]);
const TableCellStyled = styled(TableBodyCell)(() => ({
    borderBottom: 'none',
}));
const PositionHistoryCellRewrite = ({ tableApi, cell, }) => {
    const isPreviousToGroup = () => {
        let result = false;
        const { rows } = tableApi.getRowModel();
        // TODO: change forEach to classic for..loop to skip unnecessary iterations
        rows.forEach(r => {
            if (!isPositionHistoryRow(r.original) && cell.row.index - r.index === -1) {
                result = true;
            }
        });
        return result;
    };
    if (isPreviousToGroup()) {
        return (_jsx(TableCellStyled, { tableApi: tableApi, cell: cell }));
    }
    return (_jsx(TableBodyCell, { tableApi: tableApi, cell: cell }));
};
const PositionHistoryGroupRow = styled('div', { shouldForwardProp: propName => propName !== 'isLoading' })((props) => ({
    display: 'flex',
    height: 33,
    width: '100%',
    borderBottom: props.isLoading ? 'unset' :
        `1px solid ${props.theme.customColors.surface.outline}`,
    margin: props.isLoading ? `${props.theme.spacing_sizes.xs}px 0px` : '',
}));
const PositionHistoryGroupColumn = styled.div(() => ({
    display: 'flex',
    alignItems: 'baseline',
    padding: '12px 6px 6px 6px',
}));
const capitalizeFirstLetter = (s) => {
    if (!s.length)
        return '';
    if (s.length < 2) {
        return s;
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};
const PositionsHistoryRow = ({ tableApi, row, isLoading = false, skeletonStyles = {}, }) => {
    const { i18n, t } = useTranslation();
    const theme = useTheme();
    if (!isPositionHistoryRow(row.original)) {
        const date = new Date();
        if (row.original.month) {
            date.setMonth(row.original.month);
        }
        const formattedDate = row.original.month ? formatDate(date, i18n.language, 'MMMM') : '';
        return (_jsx(PositionHistoryGroupRow, { isLoading: isLoading, children: _jsxs(WithSkeleton, { isLoading: isLoading, height: 'auto', width: '100%', style: skeletonStyles, children: [_jsx(PositionHistoryGroupColumn, { style: {
                            width: 190, // TODO: move
                        }, children: _jsx(BodySmall, { color: theme.palette.text.secondary, children: capitalizeFirstLetter(formattedDate) }) }), _jsx(PositionHistoryGroupColumn, { children: _jsx(BodySmall, { color: theme.palette.text.secondary, children: t('table.current_positions.trades_done', { count: row.original.count }) }) })] }) }));
    }
    return (_jsx(TableBodyRow, { tableApi: tableApi, row: row, CellComponent: PositionHistoryCellRewrite, isLoading: isLoading }));
};
const GetPositionsHistoryQueryTake = 10;
const PositionsHistoryTable = ({ className = undefined, portfolioId, }) => {
    const { t } = useTranslation();
    const columnsDefinition = useMemo(() => getColumnsDefinition(t), [t]);
    const [paginationState, setPaginationState] = useState({
        pageIndex: 0,
        pageSize: GetPositionsHistoryQueryTake,
    });
    const skip = useMemo(() => paginationState.pageIndex * paginationState.pageSize, [paginationState]);
    const { data, isLoading, isFetching, isError, } = useGetPortfolioPositionsHistoryQuery({
        portfolioId,
        skip,
        take: GetPositionsHistoryQueryTake,
    });
    const dataFormatted = useMemo(() => {
        let result = [];
        if (IsDefined(data)) {
            Object.values(data.data).forEach((month, idx) => {
                if (idx !== 0) {
                    result.push({
                        month: month.month,
                        count: month.count,
                    });
                }
                result = result.concat(month.positions);
            });
        }
        return result;
    }, [data]);
    const isChunkEmpty = useMemo(() => !IsDefined(data) || !Object.keys(data.data).length, [data]);
    return (_jsxs(Container, { className: className, style: Object.assign({}, (isChunkEmpty && ({
            minHeight: MinHeight,
        }))), children: [!isFetching &&
                isError &&
                _jsx("p", { children: "error" }), !isError &&
                (isLoading || IsDefined(data)) && (_jsx(SkeletonGroup, { isLoading: isLoading, children: _jsx(Table, { data: dataFormatted, columnDef: columnsDefinition, rowsCount: (data === null || data === void 0 ? void 0 : data.count) || 0, paginationState: paginationState, onPaginationChange: setPaginationState, getRowId: getRowId, selectionState: { 6: true }, isLoading: isLoading, components: {
                        BodyRow: PositionsHistoryRow,
                    }, hideHeader: isLoading || (IsDefined(data) && data.count === 0), defaultSkeletonRows: 15 }) }))] }));
};
export default PositionsHistoryTable;
